import { retryRequest } from "../services/requestHelpers";
import { authenticationStore } from "../stores/authentication-store";

export const logout = () => {
  return retryRequest(
    {
      method: "delete",
      url: "/api/logout.json",
      withCredentials: "true",
    },
    true
  );
};

export const refreshToken = async () => {
  const refreshToken = authenticationStore.getRefreshToken();

  const {
    data: { jwt, refresh_token },
  } = await retryRequest({
    method: "post",
    url: "/api/refresh_token.json",
    data: {
      api_user: {
        id: authenticationStore.userId,
        refresh_token: refreshToken,
      },
    },
  });
  authenticationStore.setJWTToken(jwt);
  authenticationStore.setRefreshToken(refresh_token);
};

export const getAuthUser = () => {
  return retryRequest(
    {
      method: "get",
      url: "/api/v1/auth/me.json",
    },
    true,
    false,
    true
  );
};
